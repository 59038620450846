<template>
  <v-container fluid>

    <template>
      <div style="background-color: rgb(118 126 137);">
        <v-breadcrumbs :items="breadcrumbs">
            <template v-slot:divider>
                <v-icon>mdi-forward</v-icon>
            </template>
        </v-breadcrumbs>
      </div>
    </template>
  
    <v-row class="mt-2">        
      <v-col cols="12">
        <v-card dark outlined>
            
            <v-card-title class="secondary py-1 text-subtitle-1">
                <v-icon left dense> mdi-flag </v-icon>
                Terminal Configuration
                <v-spacer/>
            </v-card-title>

            <v-card-text>
                <v-form ref="form" lazy-validation>
                   
                    <div class="mt-5">
                        <label><B>Event Name : </B> {{ selectedEvent.EventName ? selectedEvent.EventName : 'N/A'}}</label>
                    </div>

                    <div class="mt-5">
                        <label><B>StallHolder Name : </B> {{ selectStallHolder ? selectStallHolder : 'N/A'}}</label>
                    </div>

                    <div class="mt-5">
                        <label><B>Stall Name : </B> {{ selectStall ? selectStall.StallName: 'N/A'}}</label>
                    </div>

                    <v-row>
                        <v-col cols="6">
                          <v-text-field
                              v-model="editedItem.TerminalNumber"
                              label="TerminalNumber"
                              :rules="terminalNumberRules"
                              type="number"
                              min="0"
                              required
                          ></v-text-field>
                         </v-col>
                    </v-row>

                      <v-row v-show = this.setNewObj>
                        <v-col cols="6" >
                          <v-text-field
                              v-model="editedItem.terminalsToAdd"
                              label="Total terminals to add"
                          ></v-text-field>
                          <p>Note: If you select 123 as the terminal number and request 5 terminals, terminals 123-127 will be created</p>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="6">
                          <v-text-field
                              v-model="editedItem.OrderPrefix"
                              label="Order Prefix"
                              required
                          ></v-text-field>
                        </v-col>
                        <v-col cols="5" offset-md="1" style="margin-top: 45px; font-size: 18px;">
                          <span>Use stall settings</span>
                        </v-col>
                    </v-row>
                
                    <v-row>
                      <v-col cols="6">
                        <label>Payment Profile</label>
                        <v-row class="ml-1">
                            <v-checkbox
                                v-model="editedItem.PaymentProfile"
                                label="Cash"
                                value="Cash"
                                class="mr-5"
                                :disabled="isSelectPaymentProfile"
                            ></v-checkbox>
                            <v-checkbox
                                v-model="editedItem.PaymentProfile"
                                label="Card"
                                value="Card"
                                class="mr-5"
                                :disabled="isSelectPaymentProfile"
                            ></v-checkbox>
                            <v-checkbox
                                v-model="editedItem.PaymentProfile"
                                label="Voucher"
                                value="Voucher"
                                :disabled="isSelectPaymentProfile"
                            ></v-checkbox>
                        </v-row>
                      </v-col>

                      <v-col cols="5" offset-md="1">
                        <v-checkbox
                            v-model="isSelectPaymentProfile"
                            :label="eventSettings.PaymentProfile"
                            class="mr-5"
                            style="padding-top: 12px;"
                        ></v-checkbox>
                      </v-col>

                    </v-row>
                        
                    <label>Printing Profile</label>
                    <v-row class="ml-1">

                      <v-col cols="6">
                        <v-row>
                          <div v-for="item in PrintingList" :key="item.id">
                            <v-checkbox
                                v-model="editedItem.PrintingProfile"
                                :label="item.name"
                                :value="item.name"
                                class="mr-5"
                                :rules="eventPrintRules"
                                required
                                :disabled="isSelectPrintingProfile"
                            ></v-checkbox>
                          </div>
                        </v-row>
                      </v-col>

                      <v-col cols="5" offset-md="1">
                        <v-checkbox
                            v-model="isSelectPrintingProfile"
                            :label="eventSettings.PrintingProfile"
                            class="mr-5"
                            style="margin-top: 4px; margin-left: -8px"
                        ></v-checkbox>
                      </v-col>

                    </v-row>

                    <v-row>

                      <v-col cols="6">
                        <v-combobox
                            v-model="eventSettings.InUse"
                            :items="InUseTypes"
                            item-text="name"
                            label="Terminal In Use"
                            :return-object="false"
                            required
                        ></v-combobox>
                      </v-col>

                    </v-row>
                       
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="blue darken-1"
                            text
                            @click="haddleClickCancel()"
                        >
                            Cancel
                        </v-btn>
                        <v-btn
                            color="blue darken-1"
                            @click="submitForm"
                        >
                            Submit
                        </v-btn>
                    </v-card-actions>

                </v-form>
            </v-card-text>

        </v-card>
      </v-col>
    </v-row>

  </v-container>
</template>
<script>
    import 'izitoast/dist/css/iziToast.min.css'; // loading css 
    import iziToast from 'izitoast/dist/js/iziToast.min.js';  // you have access to iziToast now
    import { mapActions, mapState } from 'vuex';
    export default {
      data: () => ({
        breadcrumbs: [
            {
                text: 'Stall',
                disabled: false,
                href: '/StallholdersStallsStaffTerminals',
            },
            {
                text: 'Terminal Configuration',
                disabled: true,
                href: '',
            },
        ],
        Action: '',
        editedItem: {
            EventId: '',
            StallholderId: '',
            StallId: '',
            TerminalNumber: '',
            PaymentProfile: [],
            PrintingProfile: '',
            // OrderNumbers: 'No',
            OrderPrefix: '',
            terminalsToAdd: 1,
            InUse: '',
            PaymentProfileInherit: true,
            PrintingProfileInherit: true
        },
        PrintingList: [
            {
                id: 1,
                name: "Print"
            },
            {
                id: 2,
                name: "Prompt"
            },
            {
                id: 3,
                name: "Bypass"
            }
        ],
        // OrderNumberTypes: [
        //     { id: true, name: 'Yes'},
        //     { id: false, name: 'No'}
        // ],
        InUseTypes: [
            { id: true, name: 'Yes'},
            { id: false, name: 'No'}
        ],
        stallHolders: [],
        stallHoldersList: [],
        selectStallHolder: '',
        selectStall: '',
        
        stalls: [],
    
        terminalNumberRules: [
            v => !!v || 'Terminal Number is required',
        ],
        eventPrintRules: [
            v => !!v || 'Printing Profile is required',
        ],

        eventSettings: {
          PaymentProfile: '',
          PrintingProfile: '',
          InUse: 'No',
          OrderNumbers: ''
        },

        terminalsToAdd: 1,

        isSelectPaymentProfile: false,
        isSelectPrintingProfile: false
      }),

      updated: function () {
      },

      computed: {
        ...mapState('filter', ['globalEventFilter', 'allEvents', 'allStallHolders']),

        setNewObj() {
          if (localStorage.getItem('setNewObj') === 'true') {
            return true
          }
          return false
        },
        
        selectedEvent: {
          get() {
            return this.globalEventFilter
          },
          set (value) {
            console.log("EVENT IS CHANGED MAKE API REQUEST 111", value)
          }
        },

        events() {
          return this.allEvents.map((obj) => {
            return {text: obj.EventName, value: obj.EventId}
          });
        }
      },
      watch: {

        setNewObj (value) {
          value
        },

        selectedEvent (val) {
            this.haddleChangeEvent(val);
        },
        isSelectPaymentProfile (val) {
          // Generated when payment profile inherit box is checked.

          if (val !== null && val === true) {
            this.selectStall = JSON.parse(localStorage.getItem('setStallselectObj'));

            this.editedItem.PaymentProfile = this.selectStall.PaymentProfile
            this.eventSettings.PaymentProfile = this.selectStall.PaymentProfile.join(', ')
            this.editedItem.PaymentProfileInherit = val;

          } else {
            this.editedItem.PaymentProfile = this.selectStall.PaymentProfile;
            this.editedItem.PaymentProfileInherit = false
          }
        },
        
        isSelectPrintingProfile (val) {
          // Generated when payment profile inherit box is checked.

          if (val !== null && val === true) {
            this.selectStall = JSON.parse(localStorage.getItem('setStallselectObj'));
            
            this.editedItem.PrintingProfile = this.selectStall.PrintingProfile;
            this.eventSettings.PrintingProfile = this.selectStall.PrintingProfile
            this.editedItem.PrintingProfileInherit = val;
          } else {
            this.editedItem.PrintingProfile = this.selectStall.PrintingProfile;
            this.editedItem.PrintingProfileInherit = false
          }
        }
      },
      created() {


        // Triggers when a terminal is created or edited

        console.log('starting created()',this.setNewObj)

        if (this.$route.params.id) {

            let TerminalObj = JSON.parse(localStorage.getItem('setTerminalObj'));
            this.selectStall = JSON.parse(localStorage.getItem('setStallselectObj'));
            
            this.Action = 'update';

            this.editedItem = TerminalObj;
            this.editedItem.PaymentProfile = TerminalObj.PaymentProfile;
            // this.editedItem.OrderNumbers = TerminalObj.OrderNumbers === true ? 'Yes' : 'No';
            this.editedItem.PaymentProfileInherit = this.isSelectPaymentProfile;
            this.editedItem.PrintingProfileInherit = this.isSelectPrintingProfile;
            this.editedItem.StallId = this.selectStall.StallId;

        } else {
            this.Action = 'create';
        }
        this.handleStallHolder();
      },
      methods: {
        ...mapActions('admin', ['createEvent']),
        ...mapActions('filter', ['initialLogin']),

        handleStallHolder: function() {
          if (this.selectedEvent.EventName !== undefined) {
            this.haddleChangeEvent(this.selectedEvent);
          }
        },
        
        haddleIsSelecters: function () {
            this.isSelectPaymentProfile = false;
            this.isSelectPrintingProfile = false;
        },

        haddleChangeEvent: function(obj) {
          // Triggers when a new terminal is created or a terminal is edited.
          console.log('Starting haddleChangeEvent: ', this.editedItem)
          
          this.stallHolders = [];
          this.editedItem.StallholderId = '';
          let selectedEventObj = this.allEvents.filter(element => {
            return element.EventId === obj.EventId;
          });

          // let selectedStallHolderObj = JSON.parse(localStorage.getItem('setStallHolderselectObj'));
          // console.log('STALL 2: ',selectedStallHolderObj)


          this.selectStall = JSON.parse(localStorage.getItem('setStallselectObj'));

          this.setTerminalObj = JSON.parse(localStorage.getItem('setTerminalObj'));

          this.editedItem.PaymentProfileInherit = this.editedItem.PaymentProfileInherit !== '' ? this.editedItem.PaymentProfileInherit : true
          this.editedItem.PrintingProfileInherit = this.editedItem.PrintingProfileInherit !== '' ? this.editedItem.PrintingProfileInherit : true
          
          this.editedItem.terminalsToAdd = this.editedItem.terminalsToAdd !== '' ? this.editedItem.terminalsToAdd : 0
          this.editedItem.OrderPrefix = this.editedItem.OrderPrefix !== '' ? this.editedItem.OrderPrefix : ''

          this.eventSettings.PaymentProfile = this.selectStall.PaymentProfile.join(', ')
          this.eventSettings.PrintingProfile = this.selectStall.PrintingProfile;
          // this.eventSettings.OrderNumbers = this.setTerminalObj.OrderNumbers

          this.stallHoldersList = selectedEventObj[0].Stallholders;
          this.stallHolders = selectedEventObj[0].Stallholders.map((obj) => {
            return {text: obj.StallholderName, value: obj.StallholderId}
          });

          this.editedItem.StallId = this.selectStall.StallId;

          // this.selectStallHolder = selectedStallHolderObj.StallholderName;          
          this.selectStall = this.selectStall.StallName;
          
          // this.stalls = selectedStallHolderObj.Stalls
          
          this.isSelectPaymentProfile = this.setTerminalObj.PaymentProfileInherit !== '' ? this.setTerminalObj.PaymentProfileInherit : true
          this.isSelectPrintingProfile = this.setTerminalObj.PrintingProfileInherit !== '' ? this.setTerminalObj.PrintingProfileInherit : true
        
        },

        haddleChangeStall: function (obj) {
          this.eventSettings.PaymentProfile = obj.PaymentProfile !== undefined ? obj.PaymentProfile.join(', ') : '';
          this.eventSettings.PrintingProfile = obj.PrintingProfile;
          // this.eventSettings.OrderNumbers = obj.OrderNumbers
        },
        submitForm: function () {
          console.log('Starting SubmitForm', this.editedItem)
          let selectedStallHolderObj = JSON.parse(localStorage.getItem('setStallHolderselectObj'))          
          this.editedItem.StallholderId = selectedStallHolderObj.StallholderId;
          
          console.log('SubmitForm', selectedStallHolderObj)
          console.log('SubmitForm this.editedItem.StallholderId', this.editedItem.StallholderId)


          try {
            if (this.$refs.form.validate()) {
              
                this.editedItem.EventId = this.selectedEvent.EventId;                
                this.editedItem.InUse = this.eventSettings.InUse === 'Yes' ? true : false;

                let event_item = [
                    {
                        Type: "Terminal",
                        Action: this.Action,
                        Properties: this.editedItem
                    }
                ]
                
                let submitEvent = {
                    event_items: event_item
                }

                console.log("submitEvent")
                console.log(submitEvent)
                this.createEvent(submitEvent).then(response => {
                    this.redirectReload();

                    iziToast.success({
                        title: 'Terminal',
                        message: 'Updating record!',
                        position: 'topRight'
                    });
                    console.log("response")
                    console.log(response)
                }).catch(err => {
                    console.log("err")
                    console.log(err)
                    iziToast.warning({
                        title: 'Terminal',
                        message: 'Fail to inserted record!',
                        position: 'topRight'
                    });
                })
            }
          } catch (e) {
            console.log(e)
          }
        },
        haddleClickCancel: function () {
          this.$router.push({ name: 'EventManagement' });
        },
        redirectReload() {
          this.initialLogin().then(response => {
            console.log(response)
          })
          this.$router.push({ name: 'EventManagement' });
        }
      }
    }
</script>